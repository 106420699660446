import H5 from '../views/h5/index.vue'
import pc from '../views/pc/index.vue'

export default [{
  path: '/h5',
  name: 'h5',
  component: H5
}, {
  path: '/pc',
  name: 'pc',
  component: pc
}]
